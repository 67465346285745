import chat from 'assets/images/icon-chat.png';
import './style.scss';
import {
  EarnedChart,
  LatestOffers,
  RecommendedCards,
  SpendCalculator,
  ModalCustom,
  CustomButton,
  PopupNotify
} from 'components';
import { useEffect, useRef, useState } from 'react';
import { useAppStore, useAuthStore, useCardsStore } from 'store';
import {
  getAvailableRewards,
  getCardAll,
  getLatestOffers,
  getRewardsSummary,
  getTotalSpending,
  clickTracking
} from 'services';
import { latestOfferType } from 'types';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { reactiveMessage, renewStatusMessage, trackingEvents } from 'const';
import { formatMoney } from 'utils';
import { browserName, isChrome, isChromium, isEdgeChromium, isLegacyEdge } from 'react-device-detect';

const filters = [
  { name: 'W', value: 'w' },
  { name: 'M', value: 'm' },
  { name: 'Y', value: 'y' }
];

export const Home = () => {
  const navigate = useNavigate();
  const params = useLocation();
  const elementRef = useRef<HTMLButtonElement>(null);

  const { user, userConfig, shadowId } = useAuthStore();
  const { setAppLoading, rewardsSummary, setRewardsSummary } = useAppStore();
  const { featuredCards, setFeaturedCards } = useCardsStore();

  const [filter, setFilter] = useState(filters[0].value);
  const [totalSpending, setTotalSpending] = useState(0);
  const [availableRewards, setAvailableRewards] = useState(0);
  const [annualValue, setAnnualValue] = useState(1500);
  const [latestOffers, setLatestOffers] = useState<latestOfferType[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [openExtnModal, setOpenExtnModal] = useState(false);
  const [closeCount, setCloseCount] = useState(0);
  const [showNotification, setShowNotification] = useState(false);

  const isShowNotify = (user?.isReactive || user?.isRenewConnection?.status) && showNotification;
  const title = user?.isReactive ? reactiveMessage : renewStatusMessage;

  useEffect(() => {
    const lastDismissed = localStorage.getItem('notificationDismissedTime');
    if (lastDismissed) {
      const currentTime = new Date().getTime();
      const timeDifference = currentTime - parseInt(lastDismissed, 10);
      if (timeDifference >= 86400000) {
        setShowNotification(true);
      }
    } else {
      setShowNotification(true);
    }
  }, []);

  useEffect(() => {
    if (!params?.search) return;
    const queryParams = new URLSearchParams(params?.search);
    const mappedCardId = queryParams.get('mappedCardId');
    const screenName = queryParams.get('screenName');
    const mappedCategory = queryParams.get('mappedCategory');
    const isYourCard = queryParams.get('isYourCard') || false;
    console.log('screenName: ', screenName);
    switch (screenName) {
      case 'recommended-card':
        if (!mappedCardId) return;
        navigate(`/cards/${mappedCardId}`);
        return;
      case 'MissedRewardDetailScreen':
        navigate(`/rewards-missed-detail/${mappedCategory}?isYourCard=${isYourCard}`);
        return;

      default:
        return;
    }
  }, [params]);

  useEffect(() => {
    if (shadowId || user?.userId || user?.hasLinkedPlaid) {
      const earnRewards = `$${formatMoney(rewardsSummary.earnedTotal)}`;
      const missedRewards = `$${formatMoney(rewardsSummary.missedTotal)}`;
      const data = user?.hasLinkedPlaid
        ? { browser_name: browserName, earned_rewards: earnRewards, missed_rewards: missedRewards }
        : { browser_name: browserName };
      clickTracking({
        eventType: trackingEvents.DESKTOP_SIGNIN_HOME_LANDING,
        shadowUserId: user?.userId ? user.userId : shadowId || '',
        deviceType: 'web',
        eventData: data
      });
    }
  }, [shadowId, user?.userId, rewardsSummary, browserName]);

  useEffect(() => {
    fetchHomeData();
  }, []);

  useEffect(() => {
    const checkHidden = localStorage.getItem('hideExtnOverlay') || 'false';
    const checkHiddenCount = Number(localStorage.getItem('hideCount')) || 0;
    if (checkHidden === 'false' && checkHiddenCount < 2) {
      setOpenExtnModal(true);
      handleExtenLandingTracking();
    }
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (elementRef.current) {
        elementRef.current.style.display = 'block';
      }
    }, 10000);

    // Cleanup timeout when the component unmounts
    return () => clearTimeout(timeout);
  }, []);

  const getTimeDifference = (timestamp: number) => {
    const currentTime = Date.now();
    return currentTime - timestamp;
  };

  useEffect(() => {
    const savedTimestamp = localStorage.getItem('hideTimestamp');
    const savedCloseCount = localStorage.getItem('hideCount');

    if (savedCloseCount) {
      setCloseCount(parseInt(savedCloseCount, 10));
    }

    if (savedTimestamp) {
      const currentTime = Date.now();
      const threeDaysInMs = 5 * 24 * 60 * 60 * 1000;
      // const threeDaysInMs = 1 * 60 * 1000; // 3 days in milliseconds
      const oneMonthInMs = 30 * 24 * 60 * 60 * 1000; // 1 month in milliseconds
      const key = trackingEvents.DESKTOP_SIGNIN_EXTN_OVERLAY_LANDING;
      const elapsedTime = getTimeDifference(parseInt(savedTimestamp, 10));

      if (closeCount >= 2) {
        // If it's the second close, switch the timeout to 1 month
        if (elapsedTime >= oneMonthInMs) {
          // After 1 month, reset
          setOpenExtnModal(true);
          handleExtenLandingTracking();
          localStorage.setItem('hideExtnOverlay', 'false');
          localStorage.removeItem('hideTimestamp');
          localStorage.removeItem('hideCount');
        } else {
          setOpenExtnModal(false);
        }
      } else {
        if (elapsedTime >= threeDaysInMs) {
          setOpenExtnModal(true);
          handleExtenLandingTracking();
          localStorage.setItem('hideExtnOverlay', 'false');
          localStorage.removeItem('hideTimestamp');
        } else {
          setOpenExtnModal(false);
        }
      }
    }
  }, [closeCount]);

  const setHidden = (url: string) => {
    handleExtenClickTracking();
    localStorage.setItem('hideExtnOverlay', 'true');
    setOpenExtnModal(!openExtnModal);
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const setHiddenTimeout = () => {
    let count = Number(localStorage.getItem('hideCount')) || 0;
    localStorage.setItem('hideExtnOverlay', 'true');
    localStorage.setItem('hideCount', `${++count}`);
    localStorage.setItem('hideTimestamp', Date.now().toString());
    setOpenExtnModal(!openExtnModal);
  };

  const fetchHomeData = async () => {
    try {
      setAppLoading(true);
      const resp = await Promise.all([
        getTotalSpending(),
        getAvailableRewards(),
        getRewardsSummary(),
        getLatestOffers(),
        getCardAll()
      ]);
      setTotalSpending(resp[0]?.data?.totalSpending || 0);
      setAvailableRewards(resp[1]?.data?.totalRewardBalance || 0);
      setRewardsSummary({ earnedTotal: resp[2]?.earnedTotal || 0, missedTotal: resp[2]?.missedTotal || 0 });
      setLatestOffers(resp[3]?.data || []);
      setFeaturedCards(resp[4]?.data || []);
      setAppLoading(false);
    } catch (err: any) {
      console.log(err);
      toast.error(err?.response?.data?.message || 'Something went wrong');
      setAppLoading(false);
    }
  };

  const handleSelectFilter = (value: string) => {
    setFilter(value);
  };

  const handleClickSeeAllCard = () => {
    handleCardAllTracking();
    navigate('/cards');
  };

  const handleClickLinkAccount = () => {
    handleLinkCardTracking();
    navigate('/linked-account', { state: { openPlaid: true } });
  };

  const handleChangeSlide = (value: number) => {
    setAnnualValue(value);
  };

  const handleClickSeeAllOffers = () => {
    handleOfferAllTracking();
    navigate('/offers');
  };

  const handleClickOffer = (offerMerchant: string) => {
    navigate('/offers', { state: { offerMerchantState: offerMerchant } });
  };

  const handleClickCard = (id: number) => {
    handleCardTracking();
    navigate(`/cards/${id}`);
  };

  const handleClickContact = () => {
    navigate(`/contact-us`);
  };

  const handleNo = () => {
    const currentTime = new Date().getTime();
    localStorage.setItem('notificationDismissedTime', currentTime.toString());
    setShowNotification(false);
  };

  const handleLinkChatTracking = async () => {
    const key = trackingEvents.DESKTOP_SIGNIN_HOME_CHAT_CLICK;
    await trackingData(key);
  };

  const handleOfferAllTracking = async () => {
    const key = trackingEvents.DESKTOP_SIGNIN_HOME_MERCHANT_OFFERS_VIEWALL_CLICK;
    await trackingData(key);
  };

  const handleCardAllTracking = async () => {
    const key = trackingEvents.DESKTOP_SIGNIN_HOME_CARD_OFFERS_VIEWALL_CLICK;
    await trackingData(key);
  };

  const handleCardTracking = async () => {
    const key = trackingEvents.DESKTOP_SIGNIN_HOME_CARD_OFFER_CLICK;
    await trackingData(key);
  };

  const handleClickSlider = async () => {
    const key = trackingEvents.DESKTOP_SIGNIN_HOME_SLIDER_CLICK;
    await trackingData(key);
  };

  const handleClickOfferNext = async () => {
    const key = trackingEvents.DESKTOP_SIGNIN_HOME_MERCHANT_OFFERS_NEXT_CLICK;
    await trackingData(key);
  };

  const handleClickCardNext = async () => {
    const key = trackingEvents.DESKTOP_SIGNIN_HOME_CARD_OFFERS_NEXT_CLICK;
    await trackingData(key);
  };

  const handleClickOfferSurvey = async () => {
    const key = trackingEvents.DESKTOP_SIGNIN_HOME_CARD_OFFERS_SURVEY_INIT;
    await trackingData(key);
  };

  const handleLinkCardTracking = async () => {
    const key = trackingEvents.DESKTOP_SIGNIN_HOME_LINK_CARD_CLICK;
    await trackingData(key);
  };

  const handleExtenClickTracking = async () => {
    const key = trackingEvents.DESKTOP_SIGNIN_EXTN_OVERLAY_CLICK;
    await trackingData(key);
  };

  const handleExtenCloseTracking = async () => {
    const key = trackingEvents.DESKTOP_SIGNIN_EXTN_OVERLAY_CLOSE;
    await trackingData(key);
  };

  const handleExtenLandingTracking = async () => {
    const key = trackingEvents.DESKTOP_SIGNIN_EXTN_OVERLAY_LANDING;
    await trackingData(key);
  };

  const handleRewardBarTracking = async (isMissed: boolean, eventData = {}) => {
    const key = isMissed
      ? trackingEvents.DESKTOP_SIGNIN_HOME_MISSED_REWARDS_BAR_CLICK
      : trackingEvents.DESKTOP_SIGNIN_HOME_EARNED_REWARDS_BAR_CLICK;
    await trackingData(key, eventData);
  };

  const trackingData = async (key: string, eventData = {}) => {
    if (shadowId || user?.userId) {
      const resp = await clickTracking({
        eventType: key,
        shadowUserId: user?.userId ? user?.userId : shadowId || '',
        deviceType: 'web',
        eventData: eventData
      });
      if (resp) {
        console.log(`success tracking ${key}`);
        console.log('completed');
      }
    }
  };

  return (
    <>
      {isShowNotify && (
        <div className="home-popup-wrapper">
          <PopupNotify title={title} id="home-id" onClickNo={handleNo} onClickYes={() => navigate('linked-account')} />
        </div>
      )}
      <div className="home-container">
        <div className="home-header-container mobile">
          <div className="home-header-title-container">
            <div className="home-header-title">
              {user?.hasLinkedPlaid ? (
                <>
                  <span>Hi {user?.firstName || 'there'}</span>,<br /> Your rewards & savings summary.
                </>
              ) : (
                <>
                  <span>Hi {user?.firstName || 'there'}</span>,<br /> Link your accounts to see your spending data.
                </>
              )}
            </div>
            <img src={chat} alt="chat" />
          </div>
          <div className="home-filter-container d-none">
            {filters.map((f) => {
              return (
                <div
                  key={f.value}
                  className={`home-filter-item ${f.value === filter && 'selected'}`}
                  onClick={() => handleSelectFilter(f.value)}>
                  {f.name}
                </div>
              );
            })}
          </div>
        </div>
        <div className="home-body">
          <div className="home-offers-container">
            <div className="home-col-1">
              <div className="home-header-container desktop">
                <div className="home-header-title-container">
                  <div className="home-header-title">
                    {user?.hasLinkedPlaid ? (
                      <>
                        <span>Hi {user?.firstName || 'there'}</span>,<br /> Your rewards & savings summary.
                      </>
                    ) : (
                      <>
                        <span>Hi {user?.firstName || 'there'}</span>,<br /> Use slider to view rewards you might be
                        missing.
                      </>
                    )}
                  </div>
                  <div className="home-filter-container d-none">
                    {filters.map((f) => {
                      return (
                        <div
                          key={f.value}
                          className={`home-filter-item ${f.value === filter && 'selected'}`}
                          onClick={() => handleSelectFilter(f.value)}>
                          {f.name}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <img
                  src={chat}
                  alt="chat"
                  onClick={() => {
                    handleLinkChatTracking();
                    setOpenModal(true);
                  }}
                />
              </div>
              {user?.hasLinkedPlaid ? (
                <EarnedChart
                  isYear
                  spent={totalSpending}
                  availableRewards={availableRewards}
                  rewardsSummary={rewardsSummary}
                  handleRewardBarTracking={handleRewardBarTracking}
                />
              ) : (
                <>
                  <SpendCalculator
                    value={annualValue}
                    handleChangeSlide={handleChangeSlide}
                    handleClickLinkAccount={handleClickLinkAccount}
                    handleClickSlide={handleClickSlider}
                  />
                </>
              )}
            </div>
            <div className={`home-col-2 ${user?.hasLinkedPlaid ? '' : 'hasLinked'}`}>
              <LatestOffers
                handleClickSeeAll={handleClickSeeAllOffers}
                data={latestOffers}
                handleClickOffer={handleClickOffer}
                handleClickOfferNext={handleClickOfferNext}
              />
            </div>
          </div>
          {featuredCards?.length > 0 && (
            <div className="home-recommended-cards">
              <RecommendedCards
                handleClickSeeAll={handleClickSeeAllCard}
                data={featuredCards}
                handleClickItem={handleClickCard}
                handleClickCardNext={handleClickCardNext}
                handleClickOfferSurvey={handleClickOfferSurvey}
              />
            </div>
          )}
        </div>

        <ModalCustom
          className="modal-annual-reward-note"
          isOpen={openModal}
          toggle={() => setOpenModal(!openModal)}
          onClose={() => setOpenModal(!openModal)}>
          <div className="modal-annual-reward-note-container">
            <div className="modal-annual-reward-note--title">Have a question?</div>
            <div>
              Get help from experts at Uthrive. Typically responds in 2-5 hrs.
              <br />
              <br /> For urgent help, please call <span className="highlight">
                {userConfig?.callContactNumber}
              </span>{' '}
              {userConfig?.callContactTime}.
            </div>
          </div>
          <CustomButton onClick={handleClickContact}>Start a chat</CustomButton>
        </ModalCustom>
        <ModalCustom
          ref={elementRef}
          className="extn-overlay-desktop"
          isOpen={openExtnModal}
          toggle={() => setHiddenTimeout()}
          onClose={() => setHiddenTimeout()}
          onCloseTracking={() => handleExtenCloseTracking()}>
          <div
            style={{
              padding: '55px 0px 55px 0px',
              display: 'flex'
            }}>
            <img
              style={{ width: '50%' }}
              src="https://stagingwp.uthrive.club/wp-content/uploads/2024/12/Nike-Offer-mobile-1.png"
            />
            <div style={{ width: '50%', paddingRight: 10 }}>
              <span
                style={{
                  backgroundColor: 'rgb(255, 213, 81)',
                  borderWidth: 0,
                  borderStyle: 'solid',
                  borderRadius: 25,
                  direction: 'ltr',
                  fontFamily: 'Roboto, Arial',
                  fontWeight: 400,
                  lineHeight: '1.2',
                  maxWidth: '100%',
                  padding: '5px 20px',
                  width: 'auto',
                  display: 'inline-block',
                  textAlign: 'center',
                  letterSpacing: 'normal',
                  fontSize: 15,
                  margin: '10px 30px'
                }}>
                New
              </span>
              <h3
                style={{
                  fontSize: 30,
                  fontFamily: 'Karla',
                  fontWeight: 'bold',
                  paddingLeft: 30,
                  paddingTop: 10,
                  letterSpacing: '-1px'
                }}>
                Use best card + savings
                <br />
                offers on Purchases
              </h3>
              <p style={{ fontSize: 18, padding: '10px 10px 10px 30px' }}>
                Booking flights or buying snickers
                <br />
                automatically view best rewards card, activate savings offers –
                <br />
                before you make the payment.
              </p>
              {/* <img
                style={{ padding: '35px 0px 20px 20px', width: 300 }}
                src="https://www.uthrive.club/wp-content/uploads/2024/12/merchants-extension.png"
              /> */}

              {isChrome && (
                <CustomButton
                  className="extn-btn"
                  onClick={() => {
                    setHidden(
                      'https://chrome.google.com/webstore/detail/uthrive-use-best-cards-fo/kmajiclhfhbncoegkfboeldogkdbmkhj?hl=en-US'
                    );
                  }}>
                  Activate on Chrome
                </CustomButton>
              )}

              {(isEdgeChromium || isLegacyEdge) && (
                <CustomButton
                  className="extn-btn"
                  onClick={() => {
                    setHidden(
                      'https://microsoftedge.microsoft.com/addons/detail/uthrive-use-best-cards-f/dnhdlmmgfndagkilkidkkjmeepkpnpdd'
                    );
                  }}>
                  Activate on Microsoft Edge
                </CustomButton>
              )}
            </div>
          </div>
        </ModalCustom>
      </div>
    </>
  );
};
