export const trackingEvents = {
  DESKTOP_EXTN_UNINSTALL_FEEDBACK_LANDING: 'DESKTOP_EXTN_UNINSTALL_FEEDBACK_LANDING',
  DESKTOP_EXTN_UNINSTALL_REINSTALL_CLICK: 'DESKTOP_EXTN_UNINSTALL_REINSTALL_CLICK',
  DESKTOP_EXTN_UNINSTALL_OPTION_CLICK: 'DESKTOP_EXTN_UNINSTALL_OPTION_CLICK',
  DESKTOP_EXTN_UNINSTALL_WRITE_INPUT_CLICK: 'DESKTOP_EXTN_UNINSTALL_WRITE_INPUT_CLICK',
  DESKTOP_EXTN_UNINSTALL_SUBMIT_CLICK: 'DESKTOP_EXTN_UNINSTALL_SUBMIT_CLICK',
  DESKTOP_MERCH_OFFER_GET_OFFER_CLICK: 'DESKTOP_MERCH_OFFER_GET_OFFER_CLICK',
  DESKTOP_OFFER_WALL_LANDING: 'DESKTOP_OFFER_WALL_LANDING',
  DESKTOP_MERCHANT_OFFER_DETAILS_LANDING: 'DESKTOP_MERCHANT_OFFER_DETAILS_LANDING',
  DESKTOP_COMPARE_REWARDS_GET_OFFER_CLICK: 'DESKTOP_COMPARE_REWARDS_GET_OFFER_CLICK',
  CONFIRM_ACCOUNT_VERIFICATION: 'CONFIRM_ACCOUNT_VERIFICATION',
  DESKTOP_NON_PREMIUM_EXISTING_USER_SUBSCRIPTION_SCREEN_LANDING:
    'DESKTOP_NON_PREMIUM_EXISTING_USER_SUBSCRIPTION_SCREEN_LANDING',
  DESKTOP_NON_PREMIUM_EXISTING_USER_INIT_SUBSCRIPTION_CLICK:
    'DESKTOP_NON_PREMIUM_EXISTING_USER_INIT_SUBSCRIPTION_CLICK',
  DESKTOP_SIGNUP_OVERLAY_CLOSE: 'DESKTOP_SIGNUP_OVERLAY_CLOSE',
  DESKTOP_SIGNUP_OVERLAY_GOOGLE_CLICK: 'DESKTOP_SIGNUP_OVERLAY_GOOGLE_CLICK',
  DESKTOP_SIGNUP_OVERLAY_FACEBOOK_CLICK: 'DESKTOP_SIGNUP_OVERLAY_FACEBOOK_CLICK',
  DESKTOP_SIGNUP_OVERLAY_EMAIL_CLICK: 'DESKTOP_SIGNUP_OVERLAY_EMAIL_CLICK',
  DESKTOP_SIGNUP_OVERLAY_PRIVACY_CLICK: 'DESKTOP_SIGNUP_OVERLAY_PRIVACY_CLICK',
  'DESKTOP_SIGNUP_OVERLAY_T&C_CLICK': 'DESKTOP_SIGNUP_OVERLAY_T&C_CLICK',
  DESKTOP_SIGNIN_OVERLAY_CLOSE: 'DESKTOP_SIGNIN_OVERLAY_CLOSE',
  DESKTOP_SIGNIN_OVERLAY_GOOGLE_CLICK: 'DESKTOP_SIGNIN_OVERLAY_GOOGLE_CLICK',
  DESKTOP_SIGNIN_OVERLAY_FACEBOOK_CLICK: 'DESKTOP_SIGNIN_OVERLAY_FACEBOOK_CLICK',
  DESKTOP_SIGNIN_OVERLAY_EMAIL_CLICK: 'DESKTOP_SIGNIN_OVERLAY_EMAIL_CLICK',
  DESKTOP_SIGNIN_OVERLAY_SIGNUP_CLICK: 'DESKTOP_SIGNIN_OVERLAY_SIGNUP_CLICK',

  DESKTOP_SIGNUP_EMAIL_OVERLAY_SIGNIN_CLICK: 'DESKTOP_SIGNUP_EMAIL_OVERLAY_SIGNIN_CLICK',
  DESKTOP_SIGNUP_EMAIL_OVERLAY_CLOSE: 'DESKTOP_SIGNUP_EMAIL_OVERLAY_CLOSE',
  DESKTOP_SIGNUP_EMAIL_ENTERED: 'DESKTOP_SIGNUP_EMAIL_ENTERED',
  DESKTOP_SIGNUP_EMAIL_PHONE_ENTERED: 'DESKTOP_SIGNUP_OVERLAY_PHONE_ENTERED',
  DESKTOP_SIGNUP_SMS_OPTIN_CLICK: 'DESKTOP_SIGNUP_SMS_OPTIN_CLICK',
  DESKTOP_SIGNUP_EMAIL_PRIVACY_CLICK: 'DESKTOP_SIGNUP_EMAIL_PRIVACY_CLICK',
  'DESKTOP_SIGNUP_EMAIL_T&C_CLICK': 'DESKTOP_SIGNUP_EMAIL_T&C_CLICK',
  DESKTOP_SIGNUP_EMAIL_ACCEPT_CONFIRM_CLICK: 'DESKTOP_SIGNUP_EMAIL_ACCEPT_CONFIRM_CLICK',
  DESKTOP_SIGNUP_EMAIL_OTP_ENTRY_BACK_CLICK: 'DESKTOP_SIGNUP_EMAIL_OTP_ENTRY_BACK_CLICK',
  DESKTOP_SIGNUP_EMAIL_OTP_ENTRY_CLOSE: 'DESKTOP_SIGNUP_EMAIL_OTP_ENTRY_CLOSE',
  DESKTOP_SIGNUP_EMAIL_RESEND_OTP_CLICK: 'DESKTOP_SIGNUP_EMAIL_RESEND_OTP_CLICK',
  DESKTOP_SIGNUP_EMAIL_OTP_CONTINUE_CLICK: 'DESKTOP_SIGNUP_EMAIL_OTP_CONTINUE_CLICK',
  DESKTOP_CONFIRM_ACCOUNT_VERIFICATION: 'DESKTOP_CONFIRM_ACCOUNT_VERIFICATION',
  DESKTOP_SIGNIN_HOME_LANDING: 'DESKTOP_SIGNIN_HOME_LANDING',
  DESKTOP_SIGNIN_TOPNAV_OFFERS_CLICK: 'DESKTOP_SIGNIN_TOPNAV_OFFERS_CLICK',
  DESKTOP_SIGNIN_TOPNAV_CARDS_CLICK: 'DESKTOP_SIGNIN_TOPNAV_CARDS_CLICK',
  DESKTOP_SIGNIN_TOPNAV_PREMIUM_CLICK: 'DESKTOP_SIGNIN_TOPNAV_PREMIUM_CLICK',
  DESKTOP_SIGNIN_HOME_CHAT_CLICK: 'DESKTOP_SIGNIN_HOME_CHAT_CLICK',
  DESKTOP_SIGNIN_HOME_MERCHANT_OFFERS_VIEWALL_CLICK: 'DESKTOP_SIGNIN_HOME_MERCHANT_OFFERS_VIEWALL_CLICK',
  DESKTOP_SIGNIN_HOME_SLIDER_CLICK: 'DESKTOP_SIGNIN_HOME_SLIDER_CLICK',
  DESKTOP_SIGNIN_HOME_MERCHANT_OFFERS_NEXT_CLICK: 'DESKTOP_SIGNIN_HOME_MERCHANT_OFFERS_NEXT_CLICK',
  DESKTOP_SIGNIN_HOME_LINK_CARD_CLICK: 'DESKTOP_SIGNIN_HOME_LINK_CARD_CLICK',
  DESKTOP_SIGNIN_HOME_CARD_OFFERS_VIEWALL_CLICK: 'DESKTOP_SIGNIN_HOME_CARD_OFFERS_VIEWALL_CLICK',
  DESKTOP_SIGNIN_HOME_PREMIUM_NOTI_CLOSE: 'DESKTOP_SIGNIN_HOME_PREMIUM_NOTI_CLOSE',
  DESKTOP_SIGNIN_HOME_CARD_OFFERS_NEXT_CLICK: 'DESKTOP_SIGNIN_HOME_CARD_OFFERS_NEXT_CLICK',
  DESKTOP_SIGNIN_HOME_CARD_OFFER_CLICK: 'DESKTOP_SIGNIN_HOME_CARD_OFFER_CLICK',
  DESKTOP_SIGNIN_HOME_PREMIUM_NOTI_CLICK: 'DESKTOP_SIGNIN_HOME_PREMIUM_NOTI_CLICK',
  DESKTOP_OFFER_WALL_BACK_CLICK: 'DESKTOP_OFFER_WALL_BACK_CLICK',
  DESKTOP_OFFER_WALL_SEARCH_OFFER_CLICK: 'DESKTOP_OFFER_WALL_SEARCH_OFFER_CLICK',

  DESKTOP_OFFER_WALL_VIEWALL_CLICK: 'DESKTOP_OFFER_WALL_VIEWALL_CLICK',
  DESKTOP_OFFER_WALL_MERCHANT_OFFER_CLICK: 'DESKTOP_OFFER_WALL_MERCHANT_OFFER_CLICK',
  DESKTOP_OFFER_WALL_SEARCH_ENTRY: 'DESKTOP_OFFER_WALL_SEARCH_ENTRY',
  DESKTOP_OFFER_WALL_SEARCH_CLOSE: 'DESKTOP_OFFER_WALL_SEARCH_CLOSE',
  DESKTOP_OFFER_WALL_SEARCH_DROPDOWN_CLICK: 'DESKTOP_OFFER_WALL_SEARCH_DROPDOWN_CLICK',
  DESKTOP_RECO_CARDS_ALL_BACK_CLICK: 'DESKTOP_RECO_CARDS_ALL_BACK_CLICK',
  DESKTOP_RECO_CARDS_ALL_LANDING: 'DESKTOP_RECO_CARDS_ALL_LANDING',
  DESKTOP_RECO_CARDS_ALL_CARD_DETAILS_CLICK: 'DESKTOP_RECO_CARDS_ALL_CARD_DETAILS_CLICK',
  DESKTOP_RECO_CARD_DETAILS_BACK_CLICK: 'DESKTOP_RECO_CARD_DETAILS_BACK_CLICK',
  DESKTOP_RECO_CARD_DETAILS_LANDING: 'DESKTOP_RECO_CARD_DETAILS_LANDING',
  DESKTOP_RECO_CARD_DETAILS_VIEW_ALL_CLICK: 'DESKTOP_RECO_CARD_DETAILS_VIEW_ALL_CLICK',
  DESKTOP_RECO_CARD_DETAILS_STICKYLINK_APPLY_CLICK: 'DESKTOP_RECO_CARD_DETAILS_STICKYLINK_APPLY_CLICK',
  DESKTOP_RECO_CARD_DETAILS_BOTTOMLINK_APPLY_CLICK: 'DESKTOP_RECO_CARD_DETAILS_BOTTOMLINK_APPLY_CLICK',
  DESKTOP_RECO_CARD_DETAILS_RATES_FEES_CLICK: 'DESKTOP_RECO_CARD_DETAILS_RATES_FEES_CLICK',
  DESKTOP_RECO_CARD_DETAILS_BOTTOM_VIEW_NEXT_CLICK: 'DESKTOP_RECO_CARD_DETAILS_BOTTOM_VIEW_NEXT_CLICK',
  DESKTOP_NON_PREMIUM_BOTTOM_CTA_CLICK: 'DESKTOP_NON_PREMIUM_BOTTOM_CTA_CLICK',
  DESKTOP_NON_PREMIUM_SELECT_PLAN_OVERLAY_CLOSE: 'DESKTOP_NON_PREMIUM_SELECT_PLAN_OVERLAY_CLOSE',
  DESKTOP_NON_PREMIUM_SELECT_PLAN_CLICK: 'DESKTOP_NON_PREMIUM_SELECT_PLAN_CLICK',
  DESKTOP_SIGNIN_HOME_EARNED_REWARDS_BAR_CLICK: 'DESKTOP_SIGNIN_HOME_EARNED_REWARDS_BAR_CLICK',
  DESKTOP_SIGNIN_HOME_MISSED_REWARDS_BAR_CLICK: 'DESKTOP_SIGNIN_HOME_MISSED_REWARDS_BAR_CLICK',
  DESKTOP_SIGNIN_HOME_CARD_OFFERS_SURVEY_INIT: 'DESKTOP_SIGNIN_HOME_CARD_OFFERS_SURVEY_INIT',
  DESKTOP_SIGNIN_MYACCOUNT_CLICK: 'DESKTOP_SIGNIN_MYACCOUNT_CLICK',
  DESKTOP_SIGNIN_MYACCOUNT_GETAPP_CLICK: 'DESKTOP_SIGNIN_MYACCOUNT_GETAPP_CLICK',
  DESKTOP_SIGNIN_MYACCOUNT_CONTACTUS_CLICK: 'DESKTOP_SIGNIN_MYACCOUNT_CONTACTUS_CLICK',
  DESKTOP_SIGNIN_MYACCOUNT_PROFILE_CLICK: 'DESKTOP_SIGNIN_MYACCOUNT_PROFILE_CLICK',
  DESKTOP_SIGNIN_MYACCOUNT_LINKED_ACCOUNTS_CLICK: 'DESKTOP_SIGNIN_MYACCOUNT_LINKED_ACCOUNTS_CLICK',
  DESKTOP_SIGNIN_MYACCOUNT_PRIVACY_CLICK: 'DESKTOP_SIGNIN_MYACCOUNT_PRIVACY_CLICK',
  'DESKTOP_SIGNIN_MYACCOUNT_T&C_CLICK': 'DESKTOP_SIGNIN_MYACCOUNT_T&C_CLICK',
  DESKTOP_SIGNIN_MYACCOUNT_SIGNOUT_CLICK: 'DESKTOP_SIGNIN_MYACCOUNT_SIGNOUT_CLICK',
  DESKTOP_SIGNIN_MYACCOUNT_GETAPP_CLOSE: 'DESKTOP_SIGNIN_MYACCOUNT_GETAPP_CLOSE',
  DESKTOP_SIGNIN_MYACCOUNT_GETAPP_CTA_CLOSE: 'DESKTOP_SIGNIN_MYACCOUNT_GETAPP_CTA_CLOSE',
  DESKTOP_SIGNIN_LINKED_ACCOUNTS_LANDING: 'DESKTOP_SIGNIN_LINKED_ACCOUNTS_LANDING',
  DESKTOP_SIGNIN_LINKED_ACCOUNTS_TOPNOTI_YES: 'DESKTOP_SIGNIN_LINKED_ACCOUNTS_TOPNOTI_YES',
  DESKTOP_SIGNIN_LINKED_ACCOUNTS_TOPNOTI_NO: 'DESKTOP_ SIGNIN_LINKED_ACCOUNTS_TOPNOTI_NO',
  DESKTOP_SIGNIN_LINKED_ACCOUNTS_UNLINK_INIT: 'DESKTOP_SIGNIN_LINKED_ACCOUNTS_UNLINK_INIT',
  DESKTOP_SIGNIN_LINKED_ACCOUNTS_BACK_CLICK: 'DESKTOP_SIGNIN_LINKED_ACCOUNTS_BACK_CLICK',
  DESKTOP_SIGNIN_MISSED_REWARDS_LANDING: 'DESKTOP_SIGNIN_MISSED_REWARDS_LANDING',
  DESKTOP_SIGNIN_MISSED_REWARDS_BACK_CLICK: 'DESKTOP_SIGNIN_MISSED_REWARDS_BACK_CLICK',
  DESKTOP_SIGNIN_MISSED_REWARDS_CATEGORY_CLICK: 'DESKTOP_SIGNIN_MISSED_REWARDS_CATEGORY_CLICK',
  DESKTOP_SIGNIN_MISSED_REWARDS_CATEGORY_RECO_CARD_CLICK: 'DESKTOP_SIGNIN_MISSED_REWARDS_CATEGORY_RECO_CARD_CLICK',
  DESKTOP_SIGNIN_MISSED_REWARDS_TXN_CLICK: 'DESKTOP_SIGNIN_MISSED_REWARDS_TXN_CLICK',
  DESKTOP_SIGNIN_MISSED_REWARDS_OTHER_CATEGORY_CLICK: 'DESKTOP_SIGNIN_MISSED_REWARDS_OTHER_CATEGORY_CLICK',
  DESKTOP_SIGNIN_MISSED_REWARDS_OVERLAY_CLOSE: 'DESKTOP_SIGNIN_MISSED_REWARDS_OVERLAY_CLOSE',
  DESKTOP_SIGNIN_MISSED_REWARDS_OVERLAY_CTA_CLICK: 'DESKTOP_SIGNIN_MISSED_REWARDS_OVERLAY_CTA_CLICK',
  DESKTOP_SIGNIN_EXTN_OVERLAY_LANDING: 'DESKTOP_SIGNIN_EXTN_OVERLAY_LANDING',
  DESKTOP_SIGNIN_EXTN_OVERLAY_CLOSE: 'DESKTOP_SIGNIN_EXTN_OVERLAY_CLOSE',
  DESKTOP_SIGNIN_EXTN_OVERLAY_CLICK: 'DESKTOP_SIGNIN_EXTN_OVERLAY_CLICK'
};
