import { LatestOfferItem } from 'components/LatestOfferItem';
import './style.scss';
import { latestOfferType } from 'types';
import { getRewardValue } from 'utils';
import { useMemo, useRef, useState } from 'react';
import back from 'assets/images/back.png';
import next from 'assets/images/next.png';
import { CustomButton, ModalCustom } from 'components';
import Slider from 'react-slick';

type props = {
  handleClickSeeAll: () => void;
  handleClickOffer: (id: string) => void;
  data: latestOfferType[];
  handleClickOfferNext?: () => void;
};

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false
};

export const LatestOffers = ({ handleClickSeeAll, handleClickOffer, data, handleClickOfferNext }: props) => {
  const sliderRef = useRef<Slider>(null);

  const [openModal, setOpenModal] = useState(false);
  const [currentIdx, setCurrentIdx] = useState(0);

  const dataRender = useMemo(() => {
    const temp = data ? [...data] : [];
    const tempDescend = temp?.splice(0, 5);
    return tempDescend.sort((a, b) => parseInt(b?.offerMerchantRate?.amount) - parseInt(a?.offerMerchantRate?.amount));
  }, [data]);

  const handleChangeSlide = (current: number) => {
    setCurrentIdx(current);
  };

  const handlePreScroll = () => {
    sliderRef.current?.slickPrev();
  };

  const handleNextScroll = () => {
    sliderRef.current?.slickNext();
  };

  const isEnd = currentIdx === 2;
  const isStart = currentIdx === 0;

  return (
    <div className="latest-offers-flexbox">
      <button onClick={handlePreScroll} className={`home-nav-icon left-btn ${isStart ? 'disabled' : ''}`}>
        <img src={back} alt="pre" />
      </button>
      <div className="latest-offers-content">
        <span className="home-tag">New</span>
        <div className="home-popularHeader">
          <div className="home-title">
            Popular Saving Offers{' '}
            <span className="rewards-missed-header-infoIcon" onClick={() => setOpenModal(true)}></span>
          </div>
          <button className="home-seeAll" onClick={handleClickSeeAll}>
            See All
          </button>
        </div>
        <div className="latest-offers-container">
          <Slider {...settings} ref={sliderRef} className="latest-offers-slider" afterChange={handleChangeSlide}>
            {dataRender?.map((e, idx) => {
              return (
                <LatestOfferItem
                  key={idx}
                  reward={getRewardValue(e?.offerMerchantRate?.kind, e?.offerMerchantRate?.amount)}
                  name={e.offerMerchant}
                  handleClickItem={() => handleClickOffer(e?.offerMerchant)}
                  logo={e.offerMerchantLogoUrl}
                  backgroundLogo={e.offerMerchantBackgroundUrl}
                />
              );
            })}
          </Slider>
        </div>
      </div>
      <button
        onClick={() => {
          handleClickOfferNext?.();
          handleNextScroll();
        }}
        className={`home-nav-icon right-btn ${isEnd ? 'disabled' : ''}`}>
        <img src={next} alt="next" />
      </button>
      <ModalCustom
        className="modal-annual-reward-note"
        isOpen={openModal}
        toggle={() => setOpenModal(!openModal)}
        onClose={() => setOpenModal(!openModal)}>
        <div className="modal-annual-reward-note-container">
          <div className="modal-annual-reward-note--title">Personalized Savings Offers</div>
          <div>
            You can stack savings from these offers in addition to rewards, cashback or any other savings available to
            you.
            <br />
            <br />
            In other words, these offers are sourced directly from merchants to let you save even more on your
            purchases.
            <br />
            <br />
            For example, you may earn 3% cash back on your credit card at Nike, and with 10% back offer - you can
            overall save 13%.
            <br />
            <br />
            Similarly, for $500 spent at Hyatt, you may get 2X points on your credit card, and with $100 back offer -
            you can overall get 500*2= 1,000 points plus $100 back.
            <br />
            <br />
            We are working hard to align these offers based on your spending habits. Feel free to search for any
            merchant, we have 100,000+ stores with more to come.
          </div>
        </div>
        <CustomButton onClick={() => setOpenModal(false)}>Close</CustomButton>
      </ModalCustom>
    </div>
  );
};
