import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import './style.scss';
import { CustomButton, MissedRewardsBoxItem, ModalCustom, PopoverDownloadApp, RecommendedCard } from 'components';
import { clickTracking, getMissedRewardDetail, getMoreMissedRewards } from 'services';
import { MissedRewardDetailDataType, MissedTransactionType, SeeMoreMissedDataType } from 'types';
import { useAppStore, useAuthStore } from 'store';
import { TransactionRewardItem } from 'components';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { fixMoney, formatMoney } from 'utils';
import iconPre from 'assets/images/back.png';
import iconNext from 'assets/images/next.png';
import cardIcon from 'assets/images/card-default-icon.png';
import chevLeft from 'assets/images/chevron-left.svg';
import { get } from 'lodash';
import chat from 'assets/images/icon-chat.png';
import { trackingEvents } from 'const';

type activeFilter = 'year' | 'month';
type activeFilterReward = 'missed' | 'recent';

export const MissedRewardsDetails = () => {
  const navigate = useNavigate();
  const { category = '' } = useParams();
  const [searchParams] = useSearchParams();
  const paramsIsYourCard = searchParams.get('isYourCard') || '';
  const { user, shadowId } = useAuthStore();

  const isYourCard = paramsIsYourCard ? JSON.parse(paramsIsYourCard) : false;

  const { userConfig } = useAuthStore();
  const { setAppLoading } = useAppStore();
  const [activeButton, setActiveButton] = useState<activeFilter>('year');
  const [activeFilterReward, setActiveFilterReward] = useState<activeFilterReward>('missed');
  const [date, setDate] = useState(moment());
  const [missedRewardDetailData, setMissedRewardDetailData] = useState<MissedRewardDetailDataType | null>(null);
  const [moreMissedData, setMoreMissedData] = useState<SeeMoreMissedDataType | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState<MissedTransactionType | null>(null);
  const [showPopover, setShowPopover] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);

  useEffect(() => {
    if (!paramsIsYourCard || !category) {
      navigate('/rewards-missed', { replace: true });
    }
  }, [paramsIsYourCard, category]);

  useEffect(() => {
    fetchMoreMissed();
  }, [category]);

  useEffect(() => {
    fetchData();
  }, [activeButton, date, category, isYourCard]);

  const handleButtonClick = (buttonType: activeFilter) => {
    setActiveButton(buttonType);
    setDate(moment());
  };

  const handleButtonRewardClick = (buttonType: activeFilterReward) => {
    setActiveFilterReward(buttonType);
  };

  const fetchData = async () => {
    try {
      setAppLoading(true);
      let params;
      if (activeButton === 'month') {
        params = { category, isYourCard: isYourCard, year: date.year(), month: date.month() + 1 };
      } else {
        params = { category, isYourCard: isYourCard };
      }
      const resp = await getMissedRewardDetail(params);
      setMissedRewardDetailData(resp.data);
      setAppLoading(false);
    } catch (err) {
      setAppLoading(false);
      console.log(err);
    }
  };

  const handleBackBtn = () => {
    navigate(-1);
  };

  const fetchMoreMissed = async () => {
    try {
      setLoading(true);
      const params = { excludeCategory: category };
      const resp = await getMoreMissedRewards(params);
      setMoreMissedData(resp.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const handleViewRecoTracking = async (eventData = {}) => {
    const key = trackingEvents.DESKTOP_SIGNIN_MISSED_REWARDS_CATEGORY_RECO_CARD_CLICK;
    await trackingData(key, eventData);
  };

  const handleTXNTracking = async (eventData = {}) => {
    const key = trackingEvents.DESKTOP_SIGNIN_MISSED_REWARDS_TXN_CLICK;
    await trackingData(key, eventData);
  };

  const handleOtherRWTracking = async (eventData = {}) => {
    const key = trackingEvents.DESKTOP_SIGNIN_MISSED_REWARDS_OTHER_CATEGORY_CLICK;
    await trackingData(key, eventData);
  };

  const handleRWCTATracking = async (eventData = {}) => {
    const key = trackingEvents.DESKTOP_SIGNIN_MISSED_REWARDS_OVERLAY_CTA_CLICK;
    await trackingData(key, eventData);
  };
  const onCloseTracking = async () => {
    const key = trackingEvents.DESKTOP_SIGNIN_MISSED_REWARDS_OVERLAY_CLOSE;
    await trackingData(key);
  };

  const trackingData = async (key: string, eventData = {}) => {
    if (shadowId || user?.userId) {
      const resp = await clickTracking({
        eventType: key,
        shadowUserId: user?.userId ? user?.userId : shadowId || '',
        deviceType: 'web',
        eventData: eventData
      });
      if (resp) {
        console.log(`success tracking ${key}`);
        console.log('completed');
      }
    }
  };

  const handleAddMonth = () => {
    if (moment(date).month() === moment().month()) {
      return;
    }
    const temp = moment(date).add(1, 'months');
    setDate(temp);
  };

  const handleSubtractMonth = () => {
    const temp = moment(date).subtract(1, 'months');
    setDate(temp);
  };

  const handleClickContact = () => {
    navigate(`/contact-us`);
  };

  const transactionData = useMemo(() => {
    switch (activeFilterReward) {
      case 'missed':
        return missedRewardDetailData?.mostMissedTransactions || [];
      case 'recent':
        return missedRewardDetailData?.mostRecentTransactions || [];
      default:
        return [];
    }
  }, [activeFilterReward, missedRewardDetailData]);

  const isShowMostMissed = missedRewardDetailData && missedRewardDetailData?.mostMissedTransactions?.length > 0;
  const isShowMostRecent = missedRewardDetailData && missedRewardDetailData?.mostRecentTransactions?.length > 0;
  const isShowRecommended =
    !!missedRewardDetailData?.mostMissedTransactions?.length &&
    !missedRewardDetailData?.mostMissedTransactions[0].isYourCard;
  console.log('selectedTransaction: ', selectedTransaction);
  console.log(transactionData);
  const potential = formatMoney(
    get(missedRewardDetailData, 'mostMissedTransactions.0.totalMissedRewardPotentialCard') || 0
  );
  const potentialCardId = get(missedRewardDetailData, 'mostMissedTransactions.0.potentialCardId');

  return (
    <>
      <div className="rewards-missed-container">
        <button className="rewards-missed-btnBack" onClick={handleBackBtn}>
          <img src={chevLeft} alt="chevron-left" />
          Back
        </button>
        <img className="chat-btn" src={chat} alt="chat" onClick={() => setOpenInfoModal(true)} />
        <div className="rewards-missed-header">
          <h2 className="rewards-missed-header-title">Missed Rewards - {missedRewardDetailData?.category?.category}</h2>
        </div>
        <div className="rewards-missed-box">
          <div>
            <div className="rewards-missed-filter">
              <button
                className={`rewards-missed-filter-left ${activeButton === 'year' ? 'active' : ''}`}
                onClick={() => handleButtonClick('year')}>
                Year
              </button>
              <button
                className={`rewards-missed-filter-right ${activeButton === 'month' ? 'active' : ''}`}
                onClick={() => handleButtonClick('month')}>
                Month
              </button>
            </div>
            {activeButton === 'month' && (
              <div className="rewards-missed-filter--month">
                <img src={iconPre} alt="icon" onClick={handleSubtractMonth} />
                <div className="rewards-missed-filter--month-value">{date.format("MMM'YY")}</div>
                <img
                  src={iconNext}
                  alt="icon"
                  onClick={handleAddMonth}
                  className={`${moment(date).month() === moment().month() ? 'maxed' : ''}`}
                />
              </div>
            )}
          </div>

          <div className="rewards-missed-flexbox">
            <div className="infobox">
              <div className="infobox-reward">
                <div className="title">
                  <img src={missedRewardDetailData?.category?.categoryImageUrl} alt="icon" />
                  <h2>{missedRewardDetailData?.category?.category}</h2>
                </div>
                <div className="infobox-reward-sm">
                  <div className="infobox-reward-sm-item">
                    <span className="infobox-reward-sm-title">
                      Net
                      <br />
                      Spent
                    </span>
                    <p className="infobox-reward-sm-price">
                      ${formatMoney(missedRewardDetailData?.category?.totalSpend || 0)}
                    </p>
                  </div>
                  <div className="infobox-reward-sm-item">
                    <span className="infobox-reward-sm-title">
                      Missed
                      <br />
                      Rewards
                    </span>
                    <p className="infobox-reward-sm-price missed">
                      ${formatMoney(missedRewardDetailData?.category?.totalMissedReward || 0)}
                    </p>
                  </div>
                </div>
              </div>
              <div>
                {isShowRecommended && (
                  <>
                    <div className="recommended-detail">
                      <span className="recommended-tag">Best card for {missedRewardDetailData?.category.category}</span>
                      <div className="recommended-detail--card-text">
                        Based on your spending habits, overall you can earn <b>${potential}</b> more on{' '}
                        {missedRewardDetailData?.category.category} and other purchases with this card:
                      </div>
                      <div className={`recommended-detail--card-icon`}>
                        <img src={cardIcon} alt="card" className="card-default" />
                        <div
                          className="recommended-detail--card-text-link"
                          onClick={() => {
                            handleViewRecoTracking({
                              category_name: missedRewardDetailData?.category.category,
                              spent_amount: `$${formatMoney(missedRewardDetailData?.category.totalSpend)}`,
                              missed_rewards: `$${formatMoney(missedRewardDetailData?.category.totalMissedReward)}`
                            });
                            window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
                            navigate(`/cards/${potentialCardId}`);
                          }}>
                          View our recommended new card.
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <h2 className="rewards-missed-transactitle">Your Top Transactions</h2>
              <div className="infobox-filter">
                {isShowMostMissed && (
                  <button
                    className={`infobox-filter-left ${activeFilterReward === 'missed' ? 'active' : ''}`}
                    onClick={() => handleButtonRewardClick('missed')}>
                    By Amount
                  </button>
                )}
                {isShowMostRecent && (
                  <button
                    className={`infobox-filter-right ${activeFilterReward === 'recent' ? 'active' : ''}`}
                    onClick={() => handleButtonRewardClick('recent')}>
                    Most Recent
                  </button>
                )}
              </div>
              {transactionData?.length !== 0 && (
                <div className="infobox-label">
                  <span>Transaction</span>
                  <span>Missed rewards</span>
                </div>
              )}
              {transactionData.map((item, key) => {
                return (
                  <TransactionRewardItem
                    category={category}
                    isYourCard={isYourCard}
                    data={item}
                    key={key}
                    handleClick={() => {
                      handleTXNTracking({
                        category_name: missedRewardDetailData?.category?.category,
                        merchant_name: item?.transactionName,
                        txn_amount: `$${fixMoney(item?.transactionAmount)}`,
                        missed_rewards: `$${formatMoney(item?.missedReward)}`
                      });
                      setSelectedTransaction(item);
                    }}
                  />
                );
              })}
              {transactionData?.length === 0 && (
                <>
                  <div className="rewards-missed--text">
                    Either there are no purchase in this month, or transactions are not received from your bank.
                    <br />
                    <br />
                    Try refreshing your cards to view updated transactions
                    <CustomButton id="popover-qr-code" onClick={() => setShowPopover(!showPopover)}>
                      Refresh Your Card Links
                    </CustomButton>
                  </div>
                  <PopoverDownloadApp
                    target="popover-qr-code"
                    isOpen={showPopover}
                    handleDone={() => setShowPopover(false)}
                    title="Refresh your card links on Uthrive App"
                    placement="top"
                    qrWidth="150px"
                    modalSize="bg"
                  />
                </>
              )}
            </div>
            <div className="cardbox">
              <div className="cardbox-title">
                <h2>See More Missed Rewards</h2>
              </div>
              {loading &&
                [1, 2, 3].map((idx) => {
                  return <div key={idx} className="missed-reward-loading" />;
                })}
              {moreMissedData?.list?.map((item, key) => {
                return (
                  <MissedRewardsBoxItem
                    data={item}
                    key={key}
                    handleClickItem={() => {
                      setMoreMissedData(null);
                      handleOtherRWTracking({
                        category_name: item?.displayCategory,
                        spent_amount: `$${formatMoney(item?.totalSpend)}`,
                        missed_rewards: `$${formatMoney(item?.totalMissedReward)}`
                      });
                      navigate(`/rewards-missed-detail/${item?.category}/?isYourCard=${item?.isYourCard}`);
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <ModalCustom
        isOpen={!!selectedTransaction}
        toggle={() => setSelectedTransaction(null)}
        onClose={() => setSelectedTransaction(null)}
        onCloseTracking={onCloseTracking}>
        <div className="modal-transaction-detail">
          <div className="modal-transaction-detail--title">
            You could&apos;ve earned <span>${fixMoney(selectedTransaction?.missedReward || 0)}</span> more.
          </div>
          <div className="modal-transaction-detail--text">
            <span className="top-msg">
              You earned{' '}
              <span className="txt-highlight">${fixMoney(selectedTransaction?.originalRewardAmount || 0)}</span> on your
              purchase of ${fixMoney(selectedTransaction?.transactionAmount || 0)} at{' '}
              {selectedTransaction?.transactionName} on{' '}
              {moment(selectedTransaction?.transactionDate).format('ddd, MM/DD/YYYY')}.
            </span>
            You could’ve earned{' '}
            <span className="txt-highlight">${fixMoney(selectedTransaction?.potentialRewardAmount || 0)}</span> on this
            purchase and other {selectedTransaction?.transactionName} purchases, if you{' '}
            {!selectedTransaction?.isYourCard ? 'get and' : ''} use this recommended card:
          </div>
          <div className="modal-transaction-detail--card mt-5">
            <div className="modal-transaction-detail--card-title">
              {selectedTransaction?.isYourCard ? 'Use your other card' : 'Our recommended new card'}
            </div>
            <div className="modal-transaction-detail--card-body">
              {!selectedTransaction?.isYourCard && (
                <div className="modal-transaction-detail--card-text">
                  Overall, get <span>${potential}</span> more on {selectedTransaction?.transactionName} and other
                  purchases.
                </div>
              )}
              <div className={`modal-transaction-detail--card-icon ${selectedTransaction?.isYourCard ? 'exist' : ''}`}>
                {selectedTransaction?.isYourCard ? (
                  <img src={selectedTransaction?.potentialAccountImageUrl} alt="card" className="card-image" />
                ) : (
                  <img src={cardIcon} alt="card" className="card-default" />
                )}
                {selectedTransaction?.isYourCard ? (
                  <div className="card-info">{`${selectedTransaction?.potentialCardName}${selectedTransaction?.yourAccountMask ? ` - ${selectedTransaction?.yourAccountMask}` : ''}`}</div>
                ) : (
                  <div
                    className="modal-transaction-detail--card-text-link"
                    onClick={() => {
                      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
                      navigate(`/cards/${selectedTransaction?.potentialCardId}`);
                    }}>
                    View our recommended new card.
                  </div>
                )}
              </div>
            </div>
          </div>
          {!selectedTransaction?.isYourCard && (
            <CustomButton
              onClick={() => {
                handleRWCTATracking({
                  category_name: missedRewardDetailData?.category?.category,
                  spent_amount: `$${fixMoney(selectedTransaction?.transactionAmount || 0)}`,
                  missed_rewards: `$${fixMoney(selectedTransaction?.missedReward || 0)}`,
                  recommended_card: `${selectedTransaction?.potentialCardName}${selectedTransaction?.yourAccountMask ? ` - ${selectedTransaction?.yourAccountMask}` : ''}`
                });
                window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
                navigate(`/cards/${selectedTransaction?.potentialCardId}`);
              }}>
              Tell me more
            </CustomButton>
          )}
        </div>
      </ModalCustom>
      <ModalCustom
        className="modal-annual-reward-note"
        isOpen={openInfoModal}
        toggle={() => setOpenInfoModal(!openInfoModal)}
        onClose={() => setOpenInfoModal(!openInfoModal)}>
        <div className="modal-annual-reward-note-container">
          <div className="modal-annual-reward-note--title">Have a question?</div>
          <div>
            Get help from experts at Uthrive. Typically responds in 2-5 hrs.
            <br />
            <br /> For urgent help, please call <span className="highlight">{userConfig?.callContactNumber}</span>{' '}
            {userConfig?.callContactTime}.
          </div>
        </div>
        <CustomButton onClick={handleClickContact}>Start a chat</CustomButton>
      </ModalCustom>
    </>
  );
};
