import iconNext from 'assets/images/next-white.png';
import iconClose from 'assets/images/close-icon-white.png';
import './style.scss';
import { useState } from 'react';

type props = {
  handleClick: () => void;
  handleCloseTracking: () => void;
  content: string;
  isShowArrow: boolean;
};
export const BannerFooter = ({ handleClick, content, isShowArrow, handleCloseTracking }: props) => {
  const closeBanner = localStorage.getItem('isCloseBanner');

  const [isClose, setIsClose] = useState(!!closeBanner);

  const handleClose = () => {
    handleCloseTracking();
    setIsClose(true);
    localStorage.setItem('isCloseBanner', 'true');
  };

  return (
    <div className={`banner-footer-container ${content && !isClose ? 'active' : 'inactive'}`}>
      <div />
      {!isShowArrow && <div className="banner-footer-arrow-up" />}
      <div className="banner-footer-content" onClick={handleClick}>
        <div className="banner-footer-text">{content}</div>
        {isShowArrow && <img src={iconNext} alt="icon" />}
      </div>
      <img src={iconClose} alt="close" className="banner-footer-icon-close" onClick={handleClose} />
    </div>
  );
};
