import React, { ReactNode, forwardRef } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import './style.scss';

type props = {
  toggle?: () => void;
  className?: string;
  isOpen: boolean;
  children: ReactNode;
  isSupportClose?: boolean;
  modalClass?: string;
  classNameBody?: string;
  subClass?: string;
  onClose?: () => void;
  onCloseTracking?: () => void;
};

export const ModalCustom = forwardRef<HTMLButtonElement, props>((props: props, ref) => {
  const {
    toggle,
    className,
    isOpen,
    children,
    isSupportClose = true,
    modalClass,
    classNameBody,
    subClass,
    onClose,
    onCloseTracking
  } = props;

  return (
    <Modal isOpen={isOpen} toggle={toggle} className={`${className || ''} modal-signup modal-lg`} centered>
      <div className={`p-3 ${subClass || 'modal-view'} ${modalClass || ''}`}>
        {isSupportClose && (
          <div className="text-right">
            {/* <button
              className="btn-close float-right m-0"
              onClick={() => {
                toggle || onClose;
              }}>
              ×
            </button> */}
            <button
              ref={ref}
              style={ref ? { display: 'none' } : { display: 'block' }}
              className="btn-close float-right m-0"
              onClick={() => {
                onCloseTracking?.();
                (toggle || onClose)?.();
              }}>
              ×
            </button>
          </div>
        )}
        <ModalBody className={`modal-item ${classNameBody}`}>{children}</ModalBody>
      </div>
    </Modal>
  );
});
