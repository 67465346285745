import { useCallback, useEffect, useState, useRef } from 'react';
import './style.scss';
import { useAppStore, useAuthStore, useLinkedAccountStore, usePlaidStore } from 'store';
import {
  clickTracking,
  getLinkedAccount,
  getLinkPlaidRenewToken,
  getMe,
  getPlaidLink,
  postRenewConnectionSuccess,
  refreshBank,
  requestUnlinkAccount,
  validatePlaid
} from 'services';
import chevLeft from 'assets/images/chevron-left.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  LinkedCard,
  NotValidateCard,
  PopupNotify,
  ModalPlaidValidate,
  LoaderPlaid,
  ModalUnlinkCard,
  ModalLinkPlaid,
  ModalManualCardPending
} from 'components';
import { ManualCard } from 'components/ManualCard';
import { PlaidLinkOnSuccessMetadata, PlaidLinkOptions, usePlaidLink } from 'react-plaid-link';
import { toast } from 'react-toastify';
import { plaidValidateMessage, trackingEvents } from 'const';
import { LinkedCardRecordType } from 'types';

export const LinkedAccount = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { openPlaid = false, renewAccountId = '' } = location?.state || {};

  const { user, setUser, shadowId } = useAuthStore();
  const { setAppLoading } = useAppStore();
  const { linkedData, setLinkedData } = useLinkedAccountStore();
  const { setShowProgress, setShowPlaidLoader, showPlaidLoader } = usePlaidStore();

  const [showLinkMore, setShowLinkMore] = useState(true);
  const [plaidToken, setPlaidToken] = useState('');
  const [plaidRenewToken, setPlaidRenewToken] = useState('');
  const [showModalPlaidValidate, setShowModalPlaidValidate] = useState(false);
  const [contentIdx, setContentIdx] = useState(2);
  const [selectedCard, setSelectedCard] = useState<LinkedCardRecordType | null>(null);
  const [showModalUnlink, setShowModalUnlink] = useState(false);
  const [openModalLinkPlaid, setOpenModalLinkPlaid] = useState(false);
  const [cardManual, setCardManual] = useState('');

  const userRef = useRef(user);
  const renewIDRef = useRef('');

  console.log('renewIDRef: ', renewIDRef.current);

  const isReactivate = user?.isReactive || false;
  const hasLinkedPlaid = user?.hasLinkedPlaid || false;

  const handleSuccess = useCallback(
    (public_token: string, metadata: PlaidLinkOnSuccessMetadata) => {
      onSuccessPlaid(public_token, metadata);
      setTimeout(function () {
        document.body.style.overflow = 'visible';
      }, 100);
    },
    [user, plaidToken]
  );

  const handleSuccessRenew = useCallback(
    (public_token: string, metadata: PlaidLinkOnSuccessMetadata) => {
      onSuccessPlaidRenew(public_token, metadata);
      setTimeout(function () {
        document.body.style.overflow = 'visible';
      }, 100);
    },
    [user, plaidRenewToken]
  );

  const config: PlaidLinkOptions = {
    onSuccess: handleSuccess,
    onExit: (err, metadata) => {
      console.log('onExit: ', err);
    },
    token: plaidToken
  };

  const configRenew: PlaidLinkOptions = {
    onSuccess: handleSuccessRenew,
    onExit: (err, metadata) => {
      console.log('onExit: ', err);
      renewIDRef.current = '';
      setTimeout(function () {
        document.body.style.overflow = 'visible';
      }, 100);
    },
    token: plaidRenewToken
  };

  const { open, ready } = usePlaidLink(config);

  const { open: openRenew, ready: readyRenew } = usePlaidLink(configRenew);

  useEffect(() => {
    userRef.current = user;
  }, [user]);

  useEffect(() => {
    if (openPlaid) {
      fetchPlaidLink();
    }
  }, [openPlaid]);

  useEffect(() => {
    if (renewAccountId) {
      renewIDRef.current = renewAccountId;
      handleGetTokenRenew(renewAccountId);
    }
  }, [renewAccountId]);

  useEffect(() => {
    if (plaidToken && ready) {
      localStorage.setItem('link_token', plaidToken);
      open();
      if (openPlaid) {
        navigate('/linked-account', { replace: true });
      }
    }
  }, [openPlaid, plaidToken, open, ready]);

  useEffect(() => {
    if (plaidRenewToken && readyRenew) {
      openRenew();
      if (renewAccountId) {
        navigate('/linked-account', { replace: true });
      }
    }
  }, [plaidRenewToken, openRenew, readyRenew, renewAccountId]);

  useEffect(() => {
    fetchLinkedAccount();
  }, []);

  useEffect(() => {
    if (shadowId || user?.userId) {
      clickTracking({
        eventType: trackingEvents.DESKTOP_SIGNIN_LINKED_ACCOUNTS_LANDING,
        shadowUserId: user?.userId ? user.userId : shadowId || '',
        deviceType: 'web',
        eventData: {}
      });
    }
  }, [shadowId, user?.userId]);

  const fetchLinkedAccount = async () => {
    try {
      setAppLoading(true);
      const resp = await getLinkedAccount();
      setLinkedData({ ...resp?.data });
      setAppLoading(false);
    } catch (err: any) {
      setAppLoading(false);
      console.log(err);
    }
  };

  const fetchPlaidLink = async () => {
    try {
      setAppLoading(true);
      const resp = await getPlaidLink();
      if (resp?.error_code) {
        toast.error('Get Plaid Link Failed');
        setAppLoading(false);
        return;
      }
      setPlaidToken(resp?.link_token);

      setAppLoading(false);
    } catch (err) {
      console.log(err);
      setAppLoading(false);
    }
  };

  const onSuccessPlaid = async (public_token: string, metadata: PlaidLinkOnSuccessMetadata) => {
    try {
      setAppLoading(true);
      const currentUser = userRef.current;

      const payload = {
        token: public_token,
        metadata
      };

      const resp = await validatePlaid(payload);

      fetchLinkedAccount();
      if (resp?.message === 'GLOBAL_ACCOUNT_LINKED') {
        setShowModalPlaidValidate(true);
        setContentIdx(1);
      } else if (resp?.message === 'ACCOUNTS_LINKED') {
        console.log('current: ', resp?.data?.response?.id);
        setShowModalPlaidValidate(true);
        setContentIdx(0);
      } else if (resp?.message === 'ACCOUNTS_LINKED_NO_PREMIUM') {
        setShowModalPlaidValidate(true);
        setContentIdx(2);
      } else {
        if (currentUser?.hasLinkedPlaid) {
          setShowProgress(true);
        } else {
          setShowPlaidLoader(true);
        }
      }
      setAppLoading(false);
    } catch (err: any) {
      console.log(err);
      setAppLoading(false);
      if (err?.response?.data?.message === 'GLOBAL_ACCOUNT_LINKED') {
        setShowModalPlaidValidate(true);
        setContentIdx(1);
      } else if (err?.response?.data?.message === 'ACCOUNTS_LINKED') {
        setShowModalPlaidValidate(true);
        setContentIdx(0);
      } else if (err?.response?.data?.message === 'ACCOUNTS_LINKED_NO_PREMIUM') {
        setShowModalPlaidValidate(true);
        setContentIdx(2);
      } else {
        toast.error('Plaid link failed');
      }
    }
  };

  const onSuccessPlaidRenew = async (public_token: string, metadata: PlaidLinkOnSuccessMetadata) => {
    try {
      const renewId = renewIDRef.current;
      if (!renewId) {
        return;
      }
      console.log('selectedCard renew: ', renewId);
      setAppLoading(true);

      const payload = {
        metadata
      };

      const resp = await postRenewConnectionSuccess(renewId, payload);

      fetchLinkedAccount();
      renewIDRef.current = '';
      if (resp?.message === 'ACCOUNTS_LINKED_NO_PREMIUM') {
        setShowModalPlaidValidate(true);
        setContentIdx(2);
      }
      setAppLoading(false);
    } catch (err) {
      console.log(err);
      setAppLoading(false);
      toast.error('Plaid Renew failed');
    }
  };

  const handleLinkMore = () => {
    handleNotiYesTracking();
    fetchPlaidLink();
  };

  const handleUnlinkAccount = async () => {
    try {
      if (!selectedCard) return;
      setAppLoading(true);
      await requestUnlinkAccount({ accountId: selectedCard?.accountId });
      toast.success('Unlinking your account successful');
      setAppLoading(false);
      setShowModalUnlink(false);
      setSelectedCard(null);
      fetchLinkedAccount();
    } catch (err: any) {
      console.log(err);
      setAppLoading(false);
    }
  };

  const handleGetTokenRenew = async (id: string) => {
    try {
      if (!id) return;
      console.log('renew connection');
      setAppLoading(true);
      const resp = await getLinkPlaidRenewToken(id);
      if (resp?.error_code || resp?.error) {
        toast.error('Get Plaid Link Failed');
        setAppLoading(false);
        return;
      }
      setPlaidRenewToken(resp?.link_token);
      setAppLoading(false);
    } catch (err: any) {
      console.log(err);
    }
  };

  const handleRefresh = async (card: LinkedCardRecordType) => {
    try {
      console.log('refresh card');
      setAppLoading(true);
      await refreshBank(card?.accountId);
      const resp = await getMe();
      setUser({ ...resp });
      fetchLinkedAccount();
      setAppLoading(false);
    } catch (err: any) {
      console.log(err);
    }
  };

  const handleConfirmLinkPlaid = () => {
    setOpenModalLinkPlaid(false);
    handleLinkMore();
  };

  const handleCloseModalPlaid = () => {
    setShowModalPlaidValidate(false);
    if (contentIdx === 4) {
      navigate('/premium');
    }
  };

  const handleClickBtn2 = () => {
    console.log('click btn 2');
    handleCloseModalPlaid();
  };

  const handleBackBtn = () => {
    handleBackTracking();
    navigate(-1);
  };

  const handleNotiYesTracking = async () => {
    const key = trackingEvents.DESKTOP_SIGNIN_LINKED_ACCOUNTS_TOPNOTI_YES;
    await trackingData(key);
  };

  const handleNotiNoTracking = async () => {
    const key = trackingEvents.DESKTOP_SIGNIN_LINKED_ACCOUNTS_TOPNOTI_NO;
    await trackingData(key);
  };

  const handleBackTracking = async () => {
    const key = trackingEvents.DESKTOP_SIGNIN_LINKED_ACCOUNTS_BACK_CLICK;
    await trackingData(key);
  };

  const handleUnlinkTracking = async () => {
    const key = trackingEvents.DESKTOP_SIGNIN_LINKED_ACCOUNTS_UNLINK_INIT;
    await trackingData(key);
  };

  const trackingData = async (key: string, eventData = {}) => {
    if (shadowId || user?.userId) {
      const resp = await clickTracking({
        eventType: key,
        shadowUserId: user?.userId ? user?.userId : shadowId || '',
        deviceType: 'web',
        eventData: eventData
      });
      if (resp) {
        console.log(`success tracking ${key}`);
        console.log('completed');
      }
    }
  };

  return (
    <>
      <div className="linked-account">
        <button className="linked-account-btnBack" onClick={handleBackBtn}>
          <img src={chevLeft} alt="chevron-left" />
          Back
        </button>
        <div className="linked-account-header mb-3">
          <h2 className="linked-account-header-title text-center">Linked Accounts</h2>
        </div>
        {showLinkMore && (
          <PopupNotify
            title="Do you want to add a card for you or a member of your household?"
            onClickYes={handleLinkMore}
            onClickNo={() => {
              handleNotiNoTracking();
              setShowLinkMore(false);
            }}
            id="askdjnaksj"
          />
        )}
        <div>
          {linkedData?.update2Links?.length > 0 && (
            <div>
              <div className="linked-account-title">Update {linkedData?.update2Links?.length} links</div>
              <div className="mb-4">
                For your data security, login to these accounts to know earned/missed rewards on latest purchases.
              </div>
              {linkedData?.update2Links?.map((card, idx) => {
                return (
                  <LinkedCard
                    data={card}
                    key={idx}
                    className="mb-4"
                    handleClick={() => {
                      if (card?.linkedStatus === 'P') {
                        setShowModalPlaidValidate(true);
                        setContentIdx(4);
                      } else {
                        // setSelectedCard(card);
                        renewIDRef.current = card?.accountId;
                        handleGetTokenRenew(card?.accountId);
                      }
                    }}
                  />
                );
              })}
            </div>
          )}
          {linkedData?.notValidated?.length > 0 && (
            <div>
              <div className="linked-account-title">Validate Accounts</div>
              <div className="mb-4">
                Uthrive didn’t receive complete account info from your bank – validate these accounts to link
                successfully.
              </div>
              {linkedData?.notValidated?.map((card, idx) => {
                return (
                  <NotValidateCard
                    data={card}
                    key={idx}
                    handleClick={() => navigate(`/linked-account/card-selection/${card.accountId}`)}
                    className="mb-4"
                  />
                );
              })}
            </div>
          )}
          {linkedData?.upToDate?.length > 0 && (
            <div className="mt-4">
              <div className="linked-account-title">Linked and up to date</div>
              {linkedData?.upToDate?.map((card, idx) => {
                return (
                  <LinkedCard
                    data={card}
                    key={idx}
                    isUpdate={false}
                    isRefresh={isReactivate && hasLinkedPlaid}
                    className="mb-4"
                    handleRefresh={() => {
                      if (isReactivate && hasLinkedPlaid) {
                        handleRefresh(card);
                        return;
                      }
                    }}
                    handleClick={() => {
                      setSelectedCard(card);
                      if (card?.linkedStatus === 'Y') {
                        handleUnlinkTracking();
                        setShowModalUnlink(true);
                      } else if (card?.linkedStatus === 'P') {
                        setShowModalPlaidValidate(true);
                        setContentIdx(4);
                      }
                    }}
                  />
                );
              })}
            </div>
          )}
          {linkedData?.manualAccounts?.length > 0 && (
            <div className="mt-4">
              <div className="linked-account-title">Manually added by you</div>
              <div className="mb-4">
                You manually added these cards - link them to get advice how to maximize rewards on your purchases.
              </div>
              {linkedData?.manualAccounts?.map((card, idx) => {
                return (
                  <ManualCard
                    data={card}
                    key={idx}
                    className="mb-4"
                    handleClick={() =>
                      card?.addedAccountStatus !== 'PENDING'
                        ? setOpenModalLinkPlaid(true)
                        : setCardManual(card?.institutionName)
                    }
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
      <LoaderPlaid isLoading={showPlaidLoader} onOpenPlaid={handleLinkMore} />
      <ModalPlaidValidate
        data={plaidValidateMessage[contentIdx]}
        isOpen={showModalPlaidValidate}
        onClose={handleCloseModalPlaid}
        handleClickBtn2={handleClickBtn2}
      />
      <ModalUnlinkCard
        isOpen={showModalUnlink}
        data={selectedCard}
        onClose={() => {
          setShowModalUnlink(false);
          setSelectedCard(null);
        }}
        handleConfirm={handleUnlinkAccount}
      />
      <ModalLinkPlaid
        isOpen={openModalLinkPlaid}
        onClose={() => setOpenModalLinkPlaid(false)}
        handleConfirm={handleConfirmLinkPlaid}
      />
      <ModalManualCardPending
        bankName={cardManual}
        isOpen={!!cardManual}
        onClose={() => setCardManual('')}
        handleConfirm={() => navigate('/contact-us')}
      />
    </>
  );
};
