import { CustomButton } from 'components/Button';
import { Popover, PopoverBody, PopoverHeader, PopoverProps } from 'reactstrap';
import './style.scss';
import QRCode from 'react-qr-code';
import { useMemo } from 'react';

type props = {
  title: string;
  qrWidth?: string;
  modalSize?: 'bg' | 'sm' | 'md';
  handleDone: (isCTA: boolean) => void;
} & PopoverProps;

export const PopoverDownloadApp = ({
  target,
  isOpen,
  title,
  handleDone,
  placement,
  qrWidth,
  modalSize = 'sm'
}: props) => {
  const isSize = useMemo(() => {
    switch (modalSize) {
      case 'sm':
        return 'sm';
      case 'bg':
        return 'bg';
      case 'md':
        return 'md';
    }
  }, [modalSize]);
  return (
    <Popover target={target} isOpen={isOpen} placement={placement} className={`popover-download-app ${isSize}`}>
      <PopoverHeader>
        <div className="popover-download-app--title">{title}</div>
        <div className="popover-download-app--icon-close" onClick={() => handleDone?.(false)}>
          X
        </div>
      </PopoverHeader>
      <PopoverBody>
        <div className="popover-download-app--qr-container">
          <QRCode
            size={256}
            style={{ height: 'auto', maxWidth: '100%', width: qrWidth ? qrWidth : '150px' }}
            value={'https://rewards.uthrive.club/3ufjmf'}
            viewBox={`0 0 200 200`}
          />
        </div>
        <div className="popover-download-app--subtitle">Scan with your phone camera to download the Uthrive App.</div>
        <CustomButton className="popover-download-app--btn" onClick={() => handleDone(true)}>
          Done
        </CustomButton>
      </PopoverBody>
    </Popover>
  );
};
