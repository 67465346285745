import React, { useEffect, useState } from 'react';
import { CustomButton, ModalCustom } from 'components';
import './style.scss';
import { MissedRewardsBoxItem } from 'components';
import { clickTracking, getMissedRewards } from 'services';
import { useAppStore, useAuthStore } from 'store';
import { MissedRewardDataType } from 'types';
import { formatMoney } from 'utils';
import moment from 'moment';
import iconPre from 'assets/images/back.png';
import iconNext from 'assets/images/next.png';
import { useNavigate } from 'react-router-dom';
import chevLeft from 'assets/images/chevron-left.svg';
import chat from 'assets/images/icon-chat.png';
import { trackingEvents } from 'const';

type activeFilter = 'year' | 'month';

const initMissedRewardData: MissedRewardDataType = {
  list: [],
  totalMissedReward: 0,
  notCalculateReward: false
};

export const MissedRewards = () => {
  const navigate = useNavigate();

  const { userConfig } = useAuthStore();
  const { setAppLoading } = useAppStore();
  const { user, shadowId } = useAuthStore();

  const [activeButton, setActiveButton] = useState<activeFilter>('year');
  const [date, setDate] = useState(moment());
  const [missedRewardsData, setMissedRewardsData] = useState<MissedRewardDataType>(initMissedRewardData);
  const [openModal, setOpenModal] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);

  useEffect(() => {
    if (!user?.hasLinkedPlaid) {
      navigate('/');
    }
  }, [user]);

  useEffect(() => {
    fetchData();
  }, [activeButton, date]);

  const fetchData = async () => {
    try {
      setAppLoading(true);
      let params = undefined;
      if (activeButton === 'month') {
        params = { year: date.year(), month: date.month() + 1 };
      }
      const resp = await getMissedRewards(params);
      setMissedRewardsData(resp.data);
      setAppLoading(false);
    } catch (err) {
      setAppLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    if (shadowId || user?.userId) {
      clickTracking({
        eventType: trackingEvents.DESKTOP_SIGNIN_MISSED_REWARDS_LANDING,
        shadowUserId: user?.userId ? user.userId : shadowId || '',
        deviceType: 'web',
        eventData: {}
      });
    }
  }, [shadowId, user?.userId]);

  const handleButtonClick = (buttonType: activeFilter) => {
    setActiveButton(buttonType);
    setDate(moment());
  };

  const handleAddMonth = () => {
    if (moment(date).month() === moment().month()) {
      return;
    }
    const temp = moment(date).add(1, 'months');
    setDate(temp);
  };

  const handleSubtractMonth = () => {
    const temp = moment(date).subtract(1, 'months');
    setDate(temp);
  };

  const handleBackBtn = () => {
    handleBackTracking();
    navigate('/', { replace: true });
  };

  const handleClickContact = () => {
    navigate(`/contact-us`);
  };

  const handleItemTracking = async (eventData = {}) => {
    const key = trackingEvents.DESKTOP_SIGNIN_MISSED_REWARDS_CATEGORY_CLICK;
    await trackingData(key, eventData);
  };

  const handleBackTracking = async () => {
    const key = trackingEvents.DESKTOP_SIGNIN_MISSED_REWARDS_BACK_CLICK;
    await trackingData(key);
  };

  const trackingData = async (key: string, eventData = {}) => {
    if (shadowId || user?.userId) {
      const resp = await clickTracking({
        eventType: key,
        shadowUserId: user?.userId ? user?.userId : shadowId || '',
        deviceType: 'web',
        eventData: eventData
      });
      if (resp) {
        console.log(`success tracking ${key}`);
        console.log('completed');
      }
    }
  };

  return (
    <>
      <div className="rewards-missed-container">
        <button className="rewards-missed-btnBack" onClick={handleBackBtn}>
          <img src={chevLeft} alt="chevron-left" />
          Back
        </button>
        <img className="chat-btn" src={chat} alt="chat" onClick={() => setOpenInfoModal(true)} />
        <div className="rewards-missed-header">
          {/* <PopupNotify /> */}
          <h2 className="rewards-missed-header-title">
            You missed{' '}
            <span className="rewards-missed-header-highlight">
              {missedRewardsData.totalMissedReward === 0
                ? '-$--'
                : `-$${formatMoney(missedRewardsData.totalMissedReward)}`}
            </span>{' '}
            in rewards {activeButton === 'year' ? <span>to date</span> : <span>in {date.format("MMM'YY")}</span>} across
            your credit and debit cards.{' '}
            <span className="rewards-missed-header-infoIcon" onClick={() => setOpenModal(true)}>
              {/* ⓘ */}
            </span>
          </h2>
        </div>
        <div className="rewards-missed-box">
          <div className="rewards-missed-filter">
            <button
              className={`rewards-missed-filter-left ${activeButton === 'year' ? 'active' : ''}`}
              onClick={() => handleButtonClick('year')}>
              Year
            </button>
            <button
              className={`rewards-missed-filter-right ${activeButton === 'month' ? 'active' : ''}`}
              onClick={() => handleButtonClick('month')}>
              Month
            </button>
          </div>
          {activeButton === 'month' && (
            <div className="rewards-missed-filter--month">
              <img src={iconPre} alt="icon" onClick={handleSubtractMonth} />
              <div className="rewards-missed-filter--month-value">{date.format("MMM'YY")}</div>
              <img
                src={iconNext}
                alt="icon"
                onClick={handleAddMonth}
                className={`${moment(date).month() === moment().month() ? 'maxed' : ''}`}
              />
            </div>
          )}
        </div>
        <div className="rewards-missed-body">
          {missedRewardsData?.list?.length === 0 && (
            <div className="text-center mt-5">
              Either there are no purchases in this month, or transactions are no received from your bank.
              <br />
              <br />
              Try refreshing your cards to view updated transactions
            </div>
          )}
          <div className="rewards-missed-wrapper">
            {missedRewardsData?.list?.map((item, key) => {
              return (
                <MissedRewardsBoxItem
                  data={item}
                  key={key}
                  handleClickItem={() => {
                    handleItemTracking({
                      category_name: item.category,
                      spent_amount: `$${formatMoney(item?.totalSpend)}`,
                      missed_rewards: `$${formatMoney(item?.totalMissedReward)}`
                    });
                    navigate(`/rewards-missed-detail/${item.category}?isYourCard=${item?.isYourCard}`);
                  }}
                />
              );
            })}
          </div>
        </div>
        <div className="rewards-missed-linkAcc">
          <CustomButton onClick={() => navigate('/linked-account')}>Link More Accounts</CustomButton>
        </div>
      </div>

      <ModalCustom
        className="modal-annual-reward-note"
        isOpen={openModal}
        toggle={() => setOpenModal(!openModal)}
        onClose={() => setOpenModal(!openModal)}>
        <div className="modal-annual-reward-note-container">
          <div className="modal-annual-reward-note--title">Missed Rewards</div>
          <div>
            Missed rewards are the difference between estimated value of rewards you would have earned on a transaction
            while using this. card, and maximum value of rewards you could have earned using your other existing or a
            new card.
            <br />
            <br />
            For example, if you made a $100 Amazon purchase with a card that offers 2% cash back on Amazon, while there
            is another card that offers 5% cash back on Amazon, so the missed rewards are calculated as $100*5% -
            $100*2% = $3
            <br />
            <br />
            Please note, the value of points, miles, and cash back for each card is based on publicly available
            information which may, at times, be different from what you see on your card account with the issuer/bank
            depending on how you choose to redeem the rewards. Missed rewards calculation is dependent on accurate
            reporting of the merchant category for the transaction.
            <br />
            <br />
            For your convenience, we have converted estimated missed reward values into USD to compare everything
            apples-to-apples.
          </div>
        </div>
        <CustomButton onClick={() => setOpenModal(!openModal)}>Close</CustomButton>
      </ModalCustom>
      <ModalCustom
        className="modal-annual-reward-note"
        isOpen={openInfoModal}
        toggle={() => setOpenInfoModal(!openInfoModal)}
        onClose={() => setOpenInfoModal(!openInfoModal)}>
        <div className="modal-annual-reward-note-container">
          <div className="modal-annual-reward-note--title">Have a question?</div>
          <div>
            Get help from experts at Uthrive. Typically responds in 2-5 hrs.
            <br />
            <br /> For urgent help, please call <span className="highlight">{userConfig?.callContactNumber}</span>{' '}
            {userConfig?.callContactTime}.
          </div>
        </div>
        <CustomButton onClick={handleClickContact}>Start a chat</CustomButton>
      </ModalCustom>
    </>
  );
};
