import React, { useEffect, useState } from 'react';
import { EarnedRewardsBoxItem, ModalCustom, CustomButton, PopupNotify, EarnedRewardsNotValidate } from 'components';
import './style.scss';
import { clickTracking, getEarnedRewards } from 'services';
import { useAppStore, useAuthStore } from 'store';
import { EarnedRewardDataType } from 'types';
import { formatMoney } from 'utils';
import moment from 'moment';
import iconPre from 'assets/images/back.png';
import iconNext from 'assets/images/next.png';
import { useNavigate } from 'react-router-dom';
import chevLeft from 'assets/images/chevron-left.svg';
import { reactiveMessage, renewStatusMessage, trackingEvents } from 'const';
import chat from 'assets/images/icon-chat.png';

type activeFilter = 'year' | 'month';

const initMissedRewardData: EarnedRewardDataType = {
  list: [],
  totalRewards: 0,
  notValidatedAccount: []
};

export const EarnedRewards = () => {
  const navigate = useNavigate();

  const { userConfig } = useAuthStore();
  const { setAppLoading } = useAppStore();
  const { user, shadowId } = useAuthStore();

  const [activeButton, setActiveButton] = useState<activeFilter>('year');
  const [date, setDate] = useState(moment());
  const [earnedRewardsData, setEarnedRewardsData] = useState<EarnedRewardDataType>(initMissedRewardData);
  const [openModal, setOpenModal] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);

  useEffect(() => {
    const lastDismissed = localStorage.getItem('notificationDismissedTime');
    if (lastDismissed) {
      const currentTime = new Date().getTime();
      const timeDifference = currentTime - parseInt(lastDismissed, 10);
      if (timeDifference >= 86400000) {
        setShowNotification(true);
      }
    } else {
      setShowNotification(true);
    }
  }, []);

  useEffect(() => {
    if (!user?.hasLinkedPlaid) {
      navigate('/');
    }
  }, [user]);

  useEffect(() => {
    fetchData();
  }, [activeButton, date]);

  const fetchData = async () => {
    try {
      setAppLoading(true);
      let params = undefined;
      if (activeButton === 'month') {
        params = { year: date.year(), month: date.month() + 1 };
      }
      const resp = await getEarnedRewards(params);
      setEarnedRewardsData(resp.data);
      setAppLoading(false);
    } catch (err) {
      setAppLoading(false);
      console.log(err);
    }
  };

  const handleButtonClick = (buttonType: activeFilter) => {
    setActiveButton(buttonType);
    setDate(moment());
  };

  const handleAddMonth = () => {
    if (moment(date).month() === moment().month()) {
      return;
    }
    const temp = moment(date).add(1, 'months');
    setDate(temp);
  };

  const handleSubtractMonth = () => {
    const temp = moment(date).subtract(1, 'months');
    setDate(temp);
  };

  const handleBackBtn = () => {
    navigate('/', { replace: true });
  };

  const handleNo = () => {
    const currentTime = new Date().getTime();
    localStorage.setItem('notificationDismissedTime', currentTime.toString());
    setShowNotification(false);
  };

  const trackingData = async (key: string, eventData = {}) => {
    if (shadowId || user?.userId) {
      const resp = await clickTracking({
        eventType: key,
        shadowUserId: user?.userId ? user?.userId : shadowId || '',
        deviceType: 'web',
        eventData: eventData
      });
      if (resp) {
        console.log(`success tracking ${key}`);
        console.log('completed');
      }
    }
  };

  const isShowNotify = (user?.isReactive || user?.isRenewConnection?.status) && showNotification;
  const title = user?.isReactive ? reactiveMessage : renewStatusMessage;

  const handleClickContact = () => {
    navigate(`/contact-us`);
  };

  return (
    <>
      <div className="rewards-missed-container">
        <button className="rewards-missed-btnBack" onClick={handleBackBtn}>
          <img src={chevLeft} alt="chevron-left" />
          Back
        </button>
        <img className="chat-btn" src={chat} alt="chat" onClick={() => setOpenInfoModal(true)} />
        <div className="rewards-missed-header">
          {isShowNotify && (
            <>
              <PopupNotify
                title={title}
                id="earned-id"
                onClickNo={handleNo}
                onClickYes={() => navigate('linked-account')}
              />
            </>
          )}
          <h2 className="rewards-missed-header-title">
            You Earned{' '}
            <span className="rewards-missed-header-highlight">+${formatMoney(earnedRewardsData.totalRewards)}</span> in
            rewards {activeButton === 'year' ? <span>to date</span> : <span>in {date.format("MMM'YY")}</span>} on your
            purchase.{' '}
            <span className="rewards-missed-header-infoIcon" onClick={() => setOpenModal(true)}>
              {/* ⓘ */}
            </span>
          </h2>
        </div>
        <div className="rewards-missed-box">
          <div className="rewards-missed-filter">
            <button
              className={`rewards-missed-filter-left ${activeButton === 'year' ? 'active' : ''}`}
              onClick={() => handleButtonClick('year')}>
              Year
            </button>
            <button
              className={`rewards-missed-filter-right ${activeButton === 'month' ? 'active' : ''}`}
              onClick={() => handleButtonClick('month')}>
              Month
            </button>
          </div>
          {activeButton === 'month' && (
            <div className="rewards-missed-filter--month">
              <img src={iconPre} alt="icon" onClick={handleSubtractMonth} />
              <div className="rewards-missed-filter--month-value">{date.format("MMM'YY")}</div>
              <img
                src={iconNext}
                alt="icon"
                onClick={handleAddMonth}
                className={`${moment(date).month() === moment().month() ? 'maxed' : ''}`}
              />
            </div>
          )}
        </div>
        <div className="rewards-missed-body">
          {earnedRewardsData?.list?.length === 0 && (
            <div className="text-center mt-5">
              Either there are no purchases in this month, or transactions are no received from your bank.
              <br />
              <br />
              Try refreshing your cards to view updated transactions
            </div>
          )}
          <div className="rewards-missed-wrapper">
            {earnedRewardsData?.list?.map((item, key) => {
              return (
                <EarnedRewardsBoxItem
                  data={item}
                  key={key}
                  handleClick={() => navigate(`/rewards-earned-detail/${item?.id}`)}
                />
              );
            })}
            {earnedRewardsData?.notValidatedAccount?.map((item, key) => {
              return <EarnedRewardsNotValidate data={item} key={key} />;
            })}
          </div>
        </div>
        <div className="rewards-missed-linkAcc">
          <CustomButton onClick={() => navigate('/linked-account')}>Link More Accounts</CustomButton>
        </div>
      </div>

      <ModalCustom
        className="modal-annual-reward-note"
        isOpen={openModal}
        toggle={() => setOpenModal(!openModal)}
        onClose={() => setOpenModal(!openModal)}>
        <div className="modal-annual-reward-note-container">
          <div className="modal-annual-reward-note--title">Earned Rewards</div>
          <div>
            Earned rewards are the estimated value of rewards you would have earned on each transaction while using this
            card.
            <br />
            <br />
            For example, if the card offers 2% cash back on Groceries, so for a Groceries transaction of $100, earned
            rewards are calculated as $100*2% = $2
            <br />
            <br />
            Similarly, if the card offers 3X miles on travel, and each mile for this card is valued at 1.25¢, so for a
            travel transaction of $100, earned rewards are calculated as $100*3*1.25¢ = $3.75
            <br />
            <br />
            Please note, the value of points, miles, and cash back for each card is based on publicly available
            information which may, at times, be different from what you see on your card account with the issuer/bank
            depending on how you choose to redeem the rewards. Earned rewards calculation is dependent on accurate
            reporting of the merchant category for the transaction.
            <br />
            <br />
            For your convenience, we have converted estimated earned reward values into USD to compare everything
            apples-to-apples.
          </div>
        </div>
        <CustomButton onClick={() => setOpenModal(false)}>Close</CustomButton>
      </ModalCustom>
      <ModalCustom
        className="modal-annual-reward-note"
        isOpen={openInfoModal}
        toggle={() => setOpenInfoModal(!openInfoModal)}
        onClose={() => setOpenInfoModal(!openInfoModal)}>
        <div className="modal-annual-reward-note-container">
          <div className="modal-annual-reward-note--title">Have a question?</div>
          <div>
            Get help from experts at Uthrive. Typically responds in 2-5 hrs.
            <br />
            <br /> For urgent help, please call <span className="highlight">{userConfig?.callContactNumber}</span>{' '}
            {userConfig?.callContactTime}.
          </div>
        </div>
        <CustomButton onClick={handleClickContact}>Start a chat</CustomButton>
      </ModalCustom>
    </>
  );
};
